<template>
  <v-container
    id="regular-tables"
    fluid
    tag="section"
  >
    <br>
    <h2 class="title-style">
      <v-icon class="icon-style">
        mdi-clipboard-clock-outline
      </v-icon> Time Slots List
    </h2>
    <br>
    <v-card
      shaped
      color="#CFD8DC"
    >
      <br>
      <br>
      <v-card-title>
        <v-row>
          <v-col
            cols="12"
            lg="4"
          >
            <v-text-field
              v-model="search"
              label="Search"
              append-icon="mdi-magnify"
              outlined
              dense
            />
          </v-col>
        </v-row>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="organizationDigiBoardTimeSlots"
        :search="search"
        class="elevation-1"
        mobile-breakpoint="100"
      >
        <template v-slot:item="{ item }">
          <tr :class="isGracePeriodCheck(item.subscription_status)">
            <td>{{ item.name }}</td>
            <td>{{ item.duration }} Sec</td>
            <td>
              {{ item.start | convertToLocal }}
            </td>
            <td>
              <font
                :color="getRunningStatusColor(item.running_status)"
                class="font-my-style"
              >
                {{ item.running_status | runningstatusUpdate }}
              </font>
            </td>
            <td>{{ item.end | convertToLocal }}</td>
            <td>
              <font
                :color="getColor(item.subscription_status)"
                class="font-my-style"
              >
                {{ item.subscription_status | statusUpdate }}
              </font>
            </td>
            <td>
              <v-btn
                class="mt-n2"
                elevation="1"
                fab
                x-small
                icon
                color="#37474F"
                :disabled="!isRenewAvailable(item.subscription_status)"
                @click="renewTimeSlot(item.digi_board_time_slot_id)"
              >
                <v-icon>mdi-autorenew</v-icon>
              </v-btn>
            </td>
            <td>
              <v-btn
                class="mt-n2"
                elevation="1"
                fab
                x-small
                icon
                color="#C62828"
                @click="showContactPage = true"
              >
                <v-icon>mdi-window-close</v-icon>
              </v-btn>
            </td>
          </tr>
        </template>
      </v-data-table>
      <div class="py-3" />
    </v-card>
    <centre-spinner
      :loading="loading"
    />
    <contact-for-sub-cancel
      v-if="showContactPage"
      @closed="showContactPage = false"
    />
  </v-container>
</template>
<script>
  import moment from 'moment';
  import spinner from 'src/views/dashboard/component/SpinnerCentre';
  import ContactForSubCancel from './ContactForSubCancel.vue';

  export default {
    name: 'OrganizationDigiBoardTimeSlotsList',
    components: {
      'centre-spinner': spinner,
      'contact-for-sub-cancel': ContactForSubCancel,
    },
    filters: {
      convertToLocal (stringDatetime) {
        return moment(stringDatetime).local().format('Do MMMM YYYY hh:mm A');
      },
      statusUpdate (status) {
      if (status === 'trial') {
        return 'Trial';
      } else if (status === 'active') {
        return 'Active*';
      } else if (status === 'grace_period') {
        return 'Grace Period';
      } else { return 'Expired'; }
    },
    runningstatusUpdate (active) {
      if (active === true) {
        return 'Used';
      };
      return 'Unused';
    },
    },
    data () {
      return {
        search: '',
        headers: [
          { text: 'Slot Name', align: 'start', value: 'name' },
          { text: 'Slot Duration', value: 'duration' },
          { text: 'Start', value: 'start' },
          { text: 'Running Status', value: 'running_status' },
          { text: 'End', value: 'end' },
          { text: 'Status', value: 'status' },
          { text: 'Renew', value: 'renew' },
          { text: 'Cancel', value: 'cancel' },
        ],
        loading: false,
        showContactPage: false,
      };
    },
    computed: {
      organizationDigiBoardTimeSlots () {
        const organizationDigiBoardsList = this.$store.getters['digiboards/getOrganizationDigiBoardTimeSlots'];
        const deviceExternalId = this.$route.params.external_id;
        const filteredDigiBoardDetails = organizationDigiBoardsList.filter(i => i.digi_board.external_id === deviceExternalId);
        const list = filteredDigiBoardDetails[0].time_slots;
        const sortedList = list.sort((a, b) => {
          return new Date(b.start) - new Date(a.start);
        });
        return sortedList;
      },
    },
    methods: {
      getColor (active) {
        if (active === 'active') {
            return '#2E7D32';
        } else if (active === 'expired') {
            return '#C62828';
        } else {
            return '#EF6C00';
        }
      },
      getRunningStatusColor (active) {
      if (active === true) {
        return '#2E7D32';
        } else {
        return '#C62828';
      }
    },
    isGracePeriodCheck (subscriptionStatus) {
      if (subscriptionStatus === 'grace_period') {
        return 'grace_period_alert_style';
      }
    },
    isRenewAvailable (status) {
      if (status === 'grace_period' || status === 'trial') {
        return true;
      }
      return false;
    },
     renewTimeSlot (digiBoardTimeSlotId) {
      const deviceExternalId = this.$route.params.external_id;
      this.$router.push(`/digi-board/${deviceExternalId}/time-slots/${digiBoardTimeSlotId}/products`);
    },
    },
  };
</script>
<style scoped>
.v-data-table::v-deep th {
  font-size: 12px !important;
  color: #37474F !important;
  font-weight: bold !important;
}
.v-data-table::v-deep td {
  font-size: 13px !important;
  color: #37474F !important;
}
.theme--light.v-data-table tbody tr:nth-of-type(even) {
  background-color: #CFD8DC
}
.v-data-table { background-color: #ECEFF1; }
.title-style {
  color: #37474F;
}
.icon-style {
  color: #37474F;
  font-size: 45px;
}
.font-my-style {
  font-weight: bold;
}
.grace_period_alert_style {
  animation: blink 1s  infinite;
}
@keyframes blink {
 100% {
   background-color: #f9d1d1;
 }
}
</style>
