<template>
  <v-container
    id="regular-tables"
    fluid
    tag="section"
  >
    <v-btn
      class="back-button"
      small
      color="red darken-3"
      @click="$router.push({ name: 'Digi-Boards' })"
    >
      <v-icon left>
        mdi-chevron-double-left
      </v-icon>
      Back
    </v-btn>
    <v-menu
      v-if="isMobile"
      offset-x
      right
    >
      <template #activator="{ on, attrs }">
        <v-icon
          dark
          large
          color="#37474F"
          v-bind="attrs"
          v-on="on"
        >
          mdi-menu
        </v-icon>
      </template>
      <v-list
        nav
        dense
      >
        <v-list-item-group
          v-model="selectedItem"
          color="#37474F"
          mandatory
        >
          <v-list-item
            link
            @click="getBoardActivityDetails()"
          >
            <v-list-item-icon>
              <v-icon style="font-size:25px;color:#37474F;font-weight:bold;">
                mdi-television-guide
              </v-icon>
            </v-list-item-icon>
            <v-list-item-title
              style="font-size:14px;color:#37474F;font-weight:bold;"
            >
              Activity
            </v-list-item-title>
          </v-list-item>
          <v-list-item
            link
            @click="getOrgDigiBoardTimeSlots()"
          >
            <v-list-item-icon>
              <v-icon style="font-size:25px;color:#37474F;font-weight:bold;">
                mdi-clipboard-clock-outline
              </v-icon>
            </v-list-item-icon>
            <v-list-item-title
              style="font-size:14px;color:#37474F;font-weight:bold;"
            >
              Time Slots
            </v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-menu>
    <v-navigation-drawer
      v-if="!isMobile"
      permanent
      color="#ECEFF1"
      expand-on-hover
      absolute
      bottom
      floating
    >
      <v-list>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="nav-title-style">
              <v-list-item-icon>
                <v-icon
                  style="font-size:25px;color:#37474F;font-weight:bold;"
                >
                  mdi-application-settings-outline
                </v-icon>
              </v-list-item-icon>
              Device Details
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-divider />
      <br>
      <v-list
        nav
        dense
      >
        <v-list-item-group
          v-model="selectedItem"
          color="#37474F"
          mandatory
        >
          <v-list-item
            link
            @click="getBoardActivityDetails()"
          >
            <v-list-item-icon>
              <v-icon style="font-size:25px;color:#37474F;font-weight:bold;">
                mdi-television-guide
              </v-icon>
            </v-list-item-icon>
            <v-list-item-title
              style="font-size:14px;color:#37474F;font-weight:bold;"
            >
              Activity
            </v-list-item-title>
          </v-list-item>
          <v-list-item
            link
            @click="getOrgDigiBoardTimeSlots()"
          >
            <v-list-item-icon>
              <v-icon style="font-size:25px;color:#37474F;font-weight:bold;">
                mdi-clipboard-clock-outline
              </v-icon>
            </v-list-item-icon>
            <v-list-item-title
              style="font-size:14px;color:#37474F;font-weight:bold;"
            >
              Time Slots
            </v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
    <div>
      <purchased-dig-board-time-slots-list v-if="showTimeSlots" />
    </div>
    <div>
      <digi-board-details v-if="showActivity" />
    </div>
  </v-container>
</template>

<script>
import purchasedDigiBoardTimeSlotsList from 'src/views/dashboard/digiboard/PurchasedDigiBoardTimeSlotsList.vue';
import digiBoardDetails from 'src/views/dashboard/digiboard/DigiBoardDetails.vue';
import global from 'src/mixins/global';
import Constants from 'src/constants';

 export default {
    name: 'DigiBoardDetailsLandingPage',
    components: {
     'purchased-dig-board-time-slots-list': purchasedDigiBoardTimeSlotsList,
     'digi-board-details': digiBoardDetails,
    },
    mixins: [global],
    data: () => ({
      selectedItem: -1,
      showActivity: true,
      showTimeSlots: false,
    }),
    beforeRouteEnter (to, from, next) {
    next(vm => {
      if (vm.permissionCheck('read-board') === false && vm.permissionCheck('read-board-update-records-list') === false) {
        vm.$router.push({ name: 'Dashboard' });
      } else {
        next();
      }
    });
  },
    computed: {
      isMobile () {
        if (this.$vuetify.breakpoint.md || this.$vuetify.breakpoint.lg || this.$vuetify.breakpoint.xl) {
          return false;
        } else {
          return true;
        }
      },
    },
    mounted () {
      this.selectedItem = 0;
    },
    methods: {
      getBoardActivityDetails () {
       this.showActivity = true;
       this.showTimeSlots = false;
    },
      getOrgDigiBoardTimeSlots () {
        if (this.permissionCheck('view-organization-purchase-list') === false) {
          this.$store.dispatch('alert/onAlert', {
            message: 'You are not authorized to perform this action. Please contact your administrator.',
            type: Constants.ALERT_TYPE_INFO,
          });
          return;
        }
       this.showActivity = false;
       this.showTimeSlots = true;
    },
    },
  };
</script>
<style scoped>
.nav-title-style {
  font-family: 'Times New Roman', Times, serif;
  font-weight: bold;
  color: #37474F;
  font-size: 25px;
}
.back-button {
  margin-top: 20px;
  margin-left: 50px;
}
</style>
