<template>
  <v-container
    fluid
  >
    <br>
    <h2 class="title-style">
      <v-icon class="icon-style">
        mdi-television-guide
      </v-icon> Device Status
    </h2>
    <v-card
      shaped
      color="#CFD8DC"
    >
      <br>
      <v-row
        justify="center"
      >
        <v-col
          cols="12"
          sm="4"
        >
          <v-card
            class="my-card-style"
          >
            <v-toolbar
              color="#37474F"
              dark
            >
              <v-toolbar-title class="my-toolbar-style">
                Device
              </v-toolbar-title>
            </v-toolbar>
            <v-container
              v-if="showOrgDigiBoardDetails"
              fluid
            >
              <p class="my-heading-style">
                Device Details
              </p>

              <p class="my-p-style">
                Device Number:-
                <font
                  class="font-my-style"
                >
                  {{ digiBoardDetails.board_number }}
                  <v-icon color="#2E7D32">
                    mdi-checkbox-marked-circle
                  </v-icon>
                </font>
              </p>
              <p class="my-p-style">
                Device External Id:-
                <font
                  class="font-my-style"
                >
                  {{ digiBoardDetails.external_id }} <v-icon color="#2E7D32">
                    mdi-checkbox-marked-circle
                  </v-icon>
                </font>
              </p>
              <p class="my-p-style">
                Device Locaton:-
                <font
                  class="font-my-style"
                >
                  {{ digiBoardDetails.location }} <v-icon color="#2E7D32">
                    mdi-checkbox-marked-circle
                  </v-icon>
                </font>
              </p>
              <hr>
              <br>
              <div v-if="organizationDigiBoardDetails !== undefined">
                <p class="my-heading-style">
                  Device Specification
                </p>
                <p
                  class="my-p-style"
                >
                  Name:-
                  <font
                    class="font-my-style first_capital_letter"
                  >
                    {{ organizationDigiBoardDetails.digi_board.digi_board_specification.name }}
                  </font>
                </p>
                <p
                  class="my-p-style"
                >
                  Display Type:-
                  <font
                    class="font-my-style capital_letter"
                  >
                    {{ organizationDigiBoardDetails.digi_board.digi_board_specification.display_type }}
                  </font>
                </p>
                <p
                  class="my-p-style"
                >
                  Dimension:-
                  <font
                    class="font-my-style"
                  >
                    {{ organizationDigiBoardDetails.digi_board.width }} * {{ organizationDigiBoardDetails.digi_board.height }}
                  </font>
                </p>
                <p
                  class="my-p-style"
                >
                  Placement:-
                  <font
                    class="font-my-style first_capital_letter"
                  >
                    {{ organizationDigiBoardDetails.digi_board.digi_board_specification.placement }}
                  </font>
                </p>
                <p
                  class="my-p-style"
                >
                  Position:-
                  <font
                    class="font-my-style first_capital_letter"
                  >
                    {{ organizationDigiBoardDetails.digi_board.digi_board_specification.position }}
                  </font>
                </p>
              </div>
            </v-container>
          </v-card>
          <v-alert
            v-if="!permissionCheck('read-board')"
            text
            prominent
            type="warning"
            color="red"
            border="left"
            class="authorization-alert"
          >
            You are not authorized to perform this action. Please contact your administrator.
          </v-alert>
        </v-col>

        <v-col
          cols="12"
          sm="6"
        >
          <v-card
            class="my-card-style"
          >
            <v-card
              dark
              flat
            >
              <v-toolbar
                color="#37474F"
                dark
              >
                <v-toolbar-title class="my-toolbar-style">
                  Device Activity
                </v-toolbar-title>
              </v-toolbar>
              <v-img
                :src="image"
                gradient="to top, rgba(0,0,0,.44), rgba(0,0,0,.44)"
              >
                <v-container class="fill-height">
                  <v-row
                    align="center"
                    justify="center"
                  >
                    <div class="my-date-style">
                      {{ currentDateTime }}
                    </div>
                  </v-row>
                </v-container>
              </v-img>
            </v-card>
          </v-card>
          <v-card-text class="py-0">
            <v-timeline
              align-top
              dense
            >
              <v-timeline-item
                v-for="(item, index) in latestThreeBoardActivities"
                :key="index"
                color="#FF3700"
                small
              >
                <v-row class="pt-1">
                  <v-col cols="3">
                    <p
                      class="my-p-style"
                    >
                      <font
                        class="font-my-style"
                      >
                        {{ item.event_triggered_at | convertStringToLocalDatetime }}
                      </font>
                    </p>
                  </v-col>
                  <v-col>
                    <strong>Device Activity {{ index + 1 }}</strong>
                    <br>
                    <br>
                    <div>
                      <p
                        class="my-p-style"
                      >
                        Published At:-
                        <font
                          class="font-my-style"
                        >
                          {{ item.event_triggered_at | convertStringToLocalDatetime }}
                        </font>
                      </p>
                    </div>
                    <div>
                      <p
                        v-if="item.board_updated_at !== null"
                        class="my-p-style"
                      >
                        Device Updated At:-
                        <font
                          class="font-my-style"
                        >
                          {{ item.board_updated_at | convertStringToLocalDatetime }}
                        </font>
                      </p>
                      <p
                        v-else
                        class="my-p-style"
                      >
                        Device Updated At:-
                        <font
                          class="font-my-style"
                        >
                          N/A
                        </font>
                      </p>
                    </div>
                    <div>
                      <p
                        class="my-p-style"
                      >
                        Device Status:-
                        <font
                          :color="getBoardStatusColor(item.status)"
                          class="my-licence-status"
                        >
                          {{ item.status | boardStatusUpdate }}
                        </font>
                      </p>
                    </div>
                  </v-col>
                </v-row>
              </v-timeline-item>
            </v-timeline>
            <v-alert
              v-if="!permissionCheck('read-board-update-records-list')"
              text
              prominent
              type="warning"
              color="red"
              border="left"
              class="authorization-alert"
            >
              You are not authorized to perform this action. Please contact your administrator.
            </v-alert>
          </v-card-text>
        </v-col>
      </v-row>
    </v-card>
    <centre-spinner
      :loading="loading"
    />
  </v-container>
</template>
<script>
import moment from 'moment';
import { cdnUrl } from 'src/app-globals';
import spinner from 'src/views/dashboard/component/SpinnerCentre';
import global from 'src/mixins/global';

export default {
  name: 'DigiBoardDetails',
  components: {
    'centre-spinner': spinner,
  },
  filters: {
    convertStringToLocalDatetime (stringDatetime) {
      return moment(stringDatetime).local().format('Do MMMM YYYY hh:mm A');
    },
    boardStatusUpdate (status) {
      if (status === 'published') {
        return 'Published';
      } else if (status === 'completed') {
        return 'Completed*';
      } else { return 'Pending'; }
    },
  },
  mixins: [global],
  data () {
    return {
      image: `${cdnUrl}/website/ActivityImage.jpg`,
      loading: false,
      digiBoardExternalId: '',
      currentDateTime: null,
      deviceExternalId: '',
    };
  },
  computed: {
    digiBoardDetails () {
      return this.$store.getters['digiboards/getBoardDetails'];
    },
    latestThreeBoardActivities () {
      return this.$store.getters['digiboards/getLatestThreeBoardActvities'];
    },
    boardActivityLastUpdatedAt () {
      return this.$store.getters['digiboards/getActivityLastUpdatedAt'];
    },
    showOrgDigiBoardDetails () {
      return Object.keys(this.digiBoardDetails).length > 0;
    },
    organizationDigiBoardDetails () {
      const organizationDigiBoardsList = this.$store.getters['digiboards/getOrganizationDigiBoardTimeSlots'];
      const filteredDigiBoardDetails = organizationDigiBoardsList.filter(i => i.digi_board.external_id === this.deviceExternalId);
      return filteredDigiBoardDetails[0];
    },
  },
  watch: {
    boardActivityLastUpdatedAt: function (val) {
      this.permissionCheck('read-board') && this.fetchDigiBoardDetails();
      this.permissionCheck('read-board-update-records-list') && this.fetchDigiBoardActivities();
    },
  },
  created () {
    this.currentDateTime = moment().format('MMMM Do YYYY, h:mm:ss A');
    setInterval(() => this.liveDateTime());
  },
  async mounted () {
    this.permissionCheck('read-board') && await this.fetchDigiBoardDetails();
    this.permissionCheck('read-board-update-records-list') && await this.fetchDigiBoardActivities();
    this.permissionCheck('view-organization-purchase-list') && await this.fetchOrganizationDigiBoardsList();
  },
  methods: {
    async fetchDigiBoardDetails () {
      const deviceExternalId = this.$route.params.external_id;
      await this.$store.dispatch('digiboards/fetchBoardDetails', {
        digiBoardId: deviceExternalId,
      });
    },
    async fetchDigiBoardActivities () {
      const deviceExternalId = this.$route.params.external_id;
      await this.$store.dispatch('digiboards/fetchLatestThreeBoardActvities', {
        params: {
          limit: 3,
          digiBoardId: deviceExternalId,
          orderBy: 'updated_at',
        },
      });
    },
    getBoardStatusColor (status) {
      if (status === 'published') {
        return '#E65100';
      } else if (status === 'completed') {
        return '#2E7D32';
      } else {
        return '#C62828';
      }
    },
    liveDateTime () {
      this.currentDateTime = moment().format('MMMM Do YYYY, h:mm:ss A');
    },
    async fetchOrganizationDigiBoardsList () {
      this.deviceExternalId = this.$route.params.external_id;
      await this.$store.dispatch('digiboards/fetchOrganizationDigiBoardTimeSlots');
    },
  },
};
</script>
<style scoped>
.title-style {
  color: #37474F;
}
.icon-style {
  color: #37474F;
  font-size: 45px;
}
.font-my-style {
  font-weight: bold;
  font-size: 15px;
  color: #37474F;
}
.my-card-style {
    border-radius: 15px;
    background-color: #ECEFF1;
}
.my-p-style {
    font-size: 16px;
    font-weight: bold;
    color: #546E7A;
    font-family:Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
}
.my-licence-status {
    font-size: 15px;
    font-weight: bold;
}
.my-heading-style {
    font-size: 17px;
    font-weight: bold;
    color: #263238;
    font-family:Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
}
.my-toolbar-style {
    font-size: 20px;
    font-weight:500;
}
.my-date-style {
  font-size: 25px;
  font-weight: bold;
}
.no-permission-message {
  color: red;
  margin-left: 5px;
  margin-right:5px;
}
.authorization-alert {
  font-family: 'Times New Roman', Times, serif;
  font-size: 16px;
}
.first_capital_letter {
  text-transform: capitalize;
}
.capital_letter {
  text-transform:uppercase;
}
</style>
